<template>
  <div>
    <Edit-navbar />
    <div class="section-1 pt-12">
      <div class="container mx-auto">
        <div
          class="grid grid-cols-6 gap-4 px-0 xl:px-0 lg:px-0 pt-12 lg:pt-24 xl:24 pb-0 xl:pb-12 lg:pb-12 md:12"
        >
          <div
            class="col-span-6 lg:col-span-2 md:col-span-6 xl:col-span-2 px-4"
          >
            <p class="uppercase font-normal">ABOUT COMPANY</p>
            <h3 class="text-5xl font-semibold mt-4">{{ h1 }}</h3>
            <div class="mt-8 w-28 h-2 bg-blue-500"></div>
            <button class="mt-8" @click="fullscreenDialog = true">
              <i
                class="fas fa-edit text-3xl text-gray-800 hover:text-blue-500"
              ></i>
            </button>
          </div>
          <div
            class="col-span-6 lg:col-span-4 md:col-span-6 xl:col-span-4 text-left px-4 xl:px-6 lg:px-6"
          >
            <h4 class="mt-10 font-semibold">{{ h2 }}</h4>
            <p class="mt-8">{{ h3 }}</p>

            <v-easy-dialog v-model="fullscreenDialog">
              <div class="bg-white overflow-y-auto">
                <div class="flex flex-col">
                  <div
                    class="text-center mt-12 text-3xl font-semibold text-gray-800"
                  >
                    About company!
                  </div>
                  <div class="flex justify-end space-x-2 mr-8">
                    <button
                      class="outline-none"
                      @click="fullscreenDialog = false"
                    >
                      <i class="fas fa-times-circle text-3xl text-gray-800"></i>
                    </button>
                  </div>
                </div>
                <div class="flex flex-col px-4 xl:px-24 lg:px-24 md:px-24">
                  <textarea
                    v-model="h1"
                    class="xl:w-96 w-full md:w-96 lg:w-96 border shadow-lg border-gray-800 rounded-lg h-40 pl-3 pt-2 mt-8  "
                    placeholder="Your Message"
                    type="text"
                  ></textarea>
                  <textarea
                    v-model="h2"
                    class="xl:w-96 w-full md:w-96 lg:w-96 border shadow-lg border-gray-800 rounded-lg h-40 pl-3 pt-2 mt-8  "
                    placeholder="Your Message"
                    type="text"
                  ></textarea>
                  <textarea
                    v-model="h3"
                    class="xl:w-96 w-full md:w-96 lg:w-96 border shadow-lg border-gray-800 rounded-lg h-40 pl-3 pt-2 mt-8  "
                    placeholder="Your Message"
                    type="text"
                  ></textarea>
                  <button
                    @click.prevent="submitHandler1"
                    class="mt-12 mb-16 bg-blue-500 h-12 rounded-md text-white transition font-semibold text-xl w-64 mx-auto hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 "
                  >
                    Submit
                  </button>
                </div>
              </div>
            </v-easy-dialog>
          </div>
        </div>
      </div>
    </div>

    <div class="section-4">
      <div class="container mx-auto px-4 md:px-4 lg:px-0">
        <v-easy-dialog v-model="fullscreenDialog2">
          <div class="bg-white overflow-y-auto">
            <div class="flex flex-col p-0">
              <div
                class="text-center mt-12 text-3xl font-semibold text-gray-800"
              >
                About US!
              </div>
              <div class="flex justify-end space-x-2 mr-8">
                <button @click="fullscreenDialog2 = false">
                  <i class="fas fa-times-circle text-3xl text-gray-800"></i>
                </button>
              </div>
            </div>
            <div class="flex flex-col px-4 xl:px-24 lg:px-24 md:px-24">
              <textarea
                v-model="h4"
                class="xl:w-96 w-full md:w-96 lg:w-96 border shadow-lg border-gray-800 rounded-lg h-40 pl-3 pt-2 mt-8  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="Your Message"
                type="text"
              ></textarea>
              <textarea
                v-model="h5"
                class="xl:w-96 w-full md:w-96 lg:w-96 border shadow-lg border-gray-800 rounded-lg h-40 pl-3 pt-2 mt-8  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="Your Message"
                type="text"
              ></textarea>
              <button
                @click.prevent="submitHandler3"
                class="mt-12 mb-16 bg-blue-500 h-12 rounded-md text-white font-semibold text-xl w-64 mx-auto hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 "
              >
                Submit
              </button>
            </div>
          </div>
        </v-easy-dialog>

        <div class="flex">
          <h2
            class="text-4xl mt-12 font-semibold mx-auto text-gray-800 border-b-4 pb-3 border-blue-500"
          >
            ABOUT US
          </h2>
        </div>
        <div>
          <div class="mt-10">
            <div class="border-l-4 border-blue-500">
              <h4
                class="text-gray-800 pl-4 text-xl xl:text-2xl lg:text-2xl md:text-2xl"
              >
                {{ h4 }}
              </h4>
            </div>
          </div>
          <div class="mt-10">
            <div class="border-l-4 border-blue-500">
              <h4
                class="text-gray-800 text-xl xl:text-2xl lg:text-2xl md:text-2xl pl-4"
              >
                {{ h5 }}
              </h4>
            </div>
          </div>
          <button class="mt-8" @click="fullscreenDialog2 = true">
            <i
              class="fas fa-edit text-3xl text-gray-800 hover:text-blue-500"
            ></i>
          </button>
        </div>
      </div>
    </div>
    <div class="section-5 container mx-auto my-12 px-4">
      <h4 class="text-xl font-semibold">About Header</h4>
      <div class="grid grid-cols-6">
        <div class="col-span-6 xl:col-span-4 md:col-span-4 lg:col-span-4">
          <textarea
            v-model="info.first.h2"
            class="w-full  border shadow-lg border-gray-800 rounded-lg h-64 xl:h-32 lg:h-32 md:h-32 px-3 py-4 mt-8  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Your Message"
            type="text"
          ></textarea>
        </div>
        <div
          class="col-span-6 xl:col-span-2 md:col-span-2 lg:col-span-2 text-center pt-16"
        >
          <button
            @click="submitFirst"
            class="bg-blue-500 text-white py-2 px-6 rounded-md"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <div class="section-6 container mx-auto my-12 px-4">
      <h4 class="text-xl font-semibold">Apply Header</h4>
      <div class="grid grid-cols-6">
        <div class="col-span-6 xl:col-span-4 md:col-span-4 lg:col-span-4">
          <textarea
            v-model="info.second.h2"
            class="w-full  border shadow-lg border-gray-800 rounded-lg h-64 xl:h-32 lg:h-32 md:h-32 px-3 py-4 mt-8  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Your Message"
            type="text"
          ></textarea>
        </div>
        <div
          class="col-span-6 xl:col-span-2 md:col-span-2 lg:col-span-2 text-center pt-16"
        >
          <button
            @click="submitSecond"
            class="bg-blue-500 text-white py-2 px-6 rounded-md"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <div class="section-7 container mx-auto my-12 px-4">
      <h4 class="text-xl font-semibold">Youtube Url</h4>
      <div class="grid grid-cols-6">
        <div class="col-span-4">
          <h5 class="mt-5">
            https://www.youtube.com<span class="text-blue-500">/embed/**</span>
          </h5>
          <input
            v-model="info.first.url"
            type="text"
            class="w-full  border shadow-lg border-gray-800 rounded-lg h-10 px-3 py-4 mt-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
        <div class="col-span-2 text-center pt-14">
          <button
            @click="submitUrl"
            class="bg-blue-500 text-white py-2 px-6 rounded-md"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EditNavbar from "../components/EditNavbar";
import VEasyDialog from "v-easy-dialog";
export default {
  data: () => ({
    home: [],
    info: [],
    fullscreenDialog: false,
    fullscreenDialog2: false,
    h1: "",
    h2: "",
    h3: "",
    h4: "",
    h5: "",
    h6: "",
  }),
  async mounted() {
    this.home = await this.$store.dispatch("fetchHome");
    this.info = await this.$store.dispatch("fetchMainHeader");

    this.h1 = this.home.company.h1;
    this.h2 = this.home.company.h2;
    this.h3 = this.home.company.h3;
    this.h4 = this.home.about.h1;
    this.h5 = this.home.about.h2;
    this.h6 = this.home.image.first.name;
  },
  methods: {
    async submitHandler1() {
      const categoryData = {
        h1: this.h1,
        h2: this.h2,
        h3: this.h3,
      };
      await this.$store.dispatch("updateProduct1", categoryData);
      this.fullscreenDialog = false;
    },
    async submitHandler3() {
      const categoryData = {
        h1: this.h4,
        h2: this.h5,
      };
      await this.$store.dispatch("updateProduct3", categoryData);
      this.fullscreenDialog2 = false;
    },
    async submitFirst() {
      const formData = {
        h2: this.info.first.h2,
      };
      await this.$store.dispatch("updateFirst", formData);
    },
    async submitSecond() {
      const formData = {
        h2: this.info.second.h2,
      };
      await this.$store.dispatch("updateSecond", formData);
    },
    async submitUrl() {
      const formData = {
        url: this.info.first.url,
      };
      await this.$store.dispatch("updateUrl", formData);
    },
  },
  components: {
    EditNavbar,
    VEasyDialog,
  },
};
</script>
<style scoped></style>
