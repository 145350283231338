import Vue from 'vue'
import Vuex from 'vuex'
import info from './info'
import auth from './auth'
import message from './message'



Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    info, auth, message
  }
})
