<template>
  <div class="p-4">
    <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th class="w-1/12 text-center py-2">№</th>
            <th class="w-1/6 text-left py-2">Name</th>
            <th class="w-1/6 text-left py-2">Mail</th>
            <th class="w-1/12 text-center py-2">Date</th>
            <th class="w-1/2 text-left py-2">Messages</th>
          </tr>
        </thead>
        <tbody v-for="(message, index) in sortedList" :key="message.id">
          <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td class="text-center py-2">{{ index + 1 }}</td>
            <td class="text-left py-2">{{ message.name }}</td>
            <td class="text-left py-2">{{ message.email }}</td>
            <td class="text-center py-2">{{ message.date }}</td>
            <td class="text-left py-2">{{ message.message }}</td>
          </tr>
        </tbody>
      </table>
      <div class="flex flex-row-reverse pr-4">
        <paginate
          v-model="page"
          :page-count="pageCount"
          :click-handler="pageChangeHandler"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :container-class="'flex pl-0 list-none rounded my-2'"
          :page-class="
            'relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 hover:bg-gray-200'
          "
          :prev-class="
            'relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 ml-0 rounded-l hover:bg-gray-200'
          "
          :next-class="
            'relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 rounded-r hover:bg-gray-200'
          "
        >
        </paginate>
      </div>
    </div>
  </div>
</template>
<script>
import paginationMixin from "../mixins/pagination.mixins";
export default {
  name: "Messages",
  mixins: [paginationMixin],
  data: () => ({
    messages: [],
  }),
  async mounted() {
    this.messages = await this.$store.dispatch("fetchMessage");
    this.setupPagination(
      this.messages.map((record) => {
        return {
          ...record,
        };
      })
    );
    console.log(this.sortedItems());
  },
  methods: {},
  computed: {
    sortedList: function () {
      // `this` points to the vm instance
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.messages.sort((a,b) => { return new Date(a.date) - new Date(b.date) })
    }
  },
};
</script>
