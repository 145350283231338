import firebase from 'firebase/app'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    async fetchInfo({commit}) {
      try {
        const comment = (await firebase.database().ref('/info').once('value')).val() || {}
        return {...comment}
      } catch (e) {
        commit('setError', e)
        throw e
      } 
    },
    async fetchHeader({commit}) {
      try {
        const comment = (await firebase.database().ref('/info/header').once('value')).val() || {}
        return {...comment}
      } catch (e) {
        commit('setError', e)
        throw e
      } 
    },
    async fetchMainHeader({commit}) {
      try {
        const comment = (await firebase.database().ref('/info/main').once('value')).val() || {}
        return {...comment}
      } catch (e) {
        commit('setError', e)
        throw e
      } 
    },
    async fetchHome({commit}) {
      try {
        const comment = (await firebase.database().ref('/info/home').once('value')).val() || {}
        return {...comment}
      } catch (e) {
        commit('setError', e)
        throw e
      } 
    },
    async updateProduct1({commit}, {h1, h2, h3}) {
      try {
        await firebase.database().ref('/info/home/company').update({h1, h2, h3})
      } catch (e) {
        commit('setError', e)
        throw e
      } 
    },
    async updateProduct3({commit}, {h1, h2}) {
      try {
        await firebase.database().ref('/info/home/about').update({h1, h2,})
      } catch (e) {
        commit('setError', e)
        throw e
      } 
    },
    async updateFirst({commit}, {h2}) {
      try {
        await firebase.database().ref('/info/main/first').update({h2})
      } catch (e) {
        commit('setError', e)
        throw e
      } 
    },
    async updateSecond({commit}, {h2}) {
      try {
        await firebase.database().ref('/info/main/second').update({h2})
      } catch (e) {
        commit('setError', e)
        throw e
      } 
    },
    async updateUrl({commit}, {url}) {
      try {
        await firebase.database().ref('/info/main/first').update({url})
      } catch (e) {
        commit('setError', e)
        throw e
      } 
    },
  },
  getters: {}
}