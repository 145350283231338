<template>
  <div class="back flex items-center justify-center h-screen">
    <div
      class="absolute left-0 ml-5 xl:ml-96 lg:ml-96 md:ml-24 top-0 mt-8 xl:mt-20 lg:mt-20 md:mt-36"
    >
      <a class="hover:text-gray-100" @click.prevent="backRoute"
        ><i
          class="fas fa-arrow-left text-4xl text-white hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        ></i
      ></a>
    </div>
    <div class="container mx-auto px-2">
      <form
        @submit.prevent="submitHandler"
        class="login text-center flex flex-col w-full md:w-2/3 xl:w-2/6  xl:mx-auto lg:w-2/6  h-auto border-2 bg-white border-gray-100 shadow-2xl mt-8 px-4 xl:px-10 md:mx-10 rounded-md"
      >
        <h2 class="text-4xl font-semibold text-gray-800 mt-12">
          SIGN <span class="text-blue-500">IN</span>
        </h2>
        <input
          v-model="email"
          class="border border-gray-800 mt-16 h-12 rounded-md pl-3 text-xl focus:ring-blue-500 focus:border-transparent"
          type="text"
          placeholder="E-mail"
        />
        <div class="text-red-500 text-base" v-if="!$v.email.email">
          Please enter a valid email address.
        </div>
        <input
          v-model="password"
          class="border border-gray-800 mt-8 h-12 rounded-md pl-3 text-xl focus:ring-blue-500 focus:border-transparent"
          type="password"
          placeholder="Password"
        />
        <div class="text-red-500 text-base" v-if="!$v.password.minLength">
          Field must have at least
          {{ $v.password.$params.minLength.min }} characters.
        </div>
        <button
          type="submit"
          class="mt-12 mb-16 bg-blue-500 h-12 rounded-md text-white font-semibold text-xl w-64 mx-auto hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 "
        >
          Log In
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import { required, email, minLength } from "vuelidate/lib/validators";
export default {
  data: () => ({
    email: "",
    password: "",
  }),
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
    },
  },
  methods: {
    backRoute() {
      this.$router.go(-1);
    },
    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      const formData = {
        email: this.email,
        password: this.password,
      };
      try {
        await this.$store.dispatch("login", formData);
        this.$router.push("/editmain");
        console.log(formData);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style scoped>
.back {
  background-color: #002482;
}
</style>
