import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
import Home from '../views/Home.vue'
import LogIn from '../views/LogIn.vue'
import Apply from '../views/Apply.vue'
import EditMain from '../views/EditMain.vue'
import SignUp from '../views/SignUp.vue'
import About from '../views/About.vue'
import Messages from '../views/Messages.vue'




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/send',
    name: 'Apply',
    component: Apply
  },
  {
    path: '/editmain',
    name: 'EditMain',
    component: EditMain,
    meta: {layout: 'EditMain', auth: true}
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages,
    meta: {layout: 'Messages', auth: true}
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    meta: {layout: 'Messages', auth: true}
  },
  {
    path: '/about',
    name: 'About',
    component: About
  }
]

const router = new VueRouter({
  routes,
  mode: 'hash', 
  scrollBehavior(){
    return {
        x: 0,
        y: 0
    }
  },
  base: process.env.BASE_URL,
})
router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser
  const requireAuth = to.matched.some(record => record.meta.auth)

  if(requireAuth && !currentUser) {
    next('./')
  } else {
    next()
  }
  
})

export default router
