<template>
  <nav
    class="fixed w-full flex flex-wrap items-center justify-between px-0 py-1 navbar-expand-lg bg-opacity-90 -mb-24 z-20"
    id="nav"
    :class="{ sticky: active }"
  >
    <div
      class="container px-0 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex justify-between lg:w-auto px-4 lg:static lg:block lg:justify-start"
      >
        <router-link
          to="/"
          class="text-2xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase text-white opacity-100"
          href="#pablo"
        >
          <img
            class="w-32 xl:w-44 md:w-32 h-auto"
            src="https://firebasestorage.googleapis.com/v0/b/truck-llc.appspot.com/o/logonew3.png?alt=media&token=6ddb1b93-c319-45bc-ad78-ebeb09e82b57"
            alt=""
          />
        </router-link>
        <button
          class="text-white cursor-pointer text-2xl leading-none  py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="toggleNavbar()"
        >
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div
        v-bind:class="{ hidden: !showMenu, flex: showMenu }"
        class="lg:flex lg:flex-grow items-center "
      >
        <ul class="flex flex-col lg:flex-row list-none ml-auto">
          <li class="nav-item ml-2">
            <a
              class="px-3 py-2 flex items-center text-lg uppercase font-bold leading-snug transition text-white hover:opacity-75"
              href="tel:+1 386-569-4107"
            >
              <span class="text-blue-500 pr-2">Call now!</span>(386)569-4185
            </a>
          </li>
          <router-link to="/about" class="nav-item ml-2">
            <a
              class="px-3 py-2 flex items-center text-lg uppercase font-bold leading-snug transition text-white hover:opacity-75"
              href=""
            >
              About
            </a>
          </router-link>
          <li class="nav-item ml-2">
            <router-link
              to="/login"
              class="px-3 py-2 flex items-center text-lg uppercase font-bold leading-snug transition text-white hover:opacity-75"
            >
              Sign in
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "pink-navbar",
  data() {
    return {
      showMenu: false,
      active: false,
    };
  },
  mounted() {
    window.document.onscroll = () => {
      let navBar = document.getElementById("nav");
      if (window.scrollY > navBar.offsetTop) {
        this.active = true;
      } else {
        this.active = false;
      }
    };
  },
  methods: {
    toggleNavbar: function() {
      this.showMenu = !this.showMenu;
      this.active = true;
    },
    toggleNavClass() {
      if (this.active == false) {
        return "nav";
      } else {
        return "sticky-nav";
      }
    },
  },
};
</script>
<style scoped>
.nav {
  transition: 200ms;
  padding: 25px;
}

.sticky-nav {
  transition: 200ms;
  padding: 20px;
}

#nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 0;
}

#nav.sticky {
  transition: 150ms;
  background-color: #010462;
}
</style>
