<template>
  <div>
    <footer class="footer mt-0 pb-4 back relative pt-1">
      <div class="container mx-auto px-6 mt-12">
        <div class="grid grid-cols-6">
          <div class="col-span-6 lg:col-span-6 md:col-span-6 xl:col-span-6">
            <div>
              <h2 class="text-center text-3xl font-semibold text-gray-800">USHR LLC</h2>
            </div>
          </div>
          <div v-scrollanimation class="col-span-6 lg:col-span-6 md:col-span-6 xl:col-span-6 xl:mt-6 lg:mt-6 md:mt-6 mt-6 border-t-2 border-b-2 mb-12">
            <div class="px-0 xl:px-64 lg:px-64 py-5">
              <ul class="flex flex-wrap text-xl font-semibold text-gray-800 ">
                <router-link tag="li" to="/about" class="pb-1 text-center mx-auto hover:text-blue-500 transition">
                  <a class="" href="#">About us</a>
                </router-link>
                <router-link tag="li" to="/send" class="pb-1 text-center mx-auto hover:text-blue-500 transition">
                  <a href="#">Apply</a>
                </router-link>
                <router-link tag="li" to="/login" class="pb-1 text-center mx-auto hover:text-blue-500 transition">
                  <a href="#">Sign in</a>
                </router-link>
              </ul>
            </div>
          </div>
          <div class="col-span-6 lg:col-span-3 md:col-span-3 xl:col-span-3">
            <div v-scrollanimation>
              <h4 class="text-xl text-gray-800">Company Information</h4>
              <div class="mt-6 xl:mt-6 lg:mt-6">
                <li class="flex">
                  <i class="fas fa-map-marker-alt pt-1 pr-3 text-blue-500"></i>
                  <a href="https://www.google.com/maps/place/4721+E+Moody+Blvd+%23505,+Bunnell,+FL+32110,+USA/@29.4743913,-81.2270558,17z/data=!3m1!4b1!4m5!3m4!1s0x88e6eb15dd75a305:0x9c165337d7469e90!8m2!3d29.4743913!4d-81.2248671" class="font-semibold text-gray-800">4721 E.Moody Blvd, Ste 201, Bunnell, FL 32110</a>
                </li>
                <div class="flex sm:flex-col flex-col md:flex-col lg:flex-row xl:flex-row mt-4">
                  <li class="flex">
                    <i class="fas fa-phone-alt pt-1 pr-3 text-blue-500"></i>
                    <a href="tel:+1 386-569-4185" class="font-semibold text-gray-800">(386)569-4185</a>
                  </li>
                  <li class="flex mt-3 md:mt-3 xl:mt-0 lg:mt-0 ml-0 md:ml-0 xl:ml-12 lg:ml-12">
                    <i class="far fa-envelope pt-1 pr-3 text-blue-500"></i>
                    <a href="mailto: contact@ushrllc.com" class="font-semibold text-gray-800">contact@ushrllc.com</a>
                  </li>
                </div>
              </div>
            </div>
          </div>
          <div v-scrollanimation class="col-span-6 lg:col-span-3 md:col-span-3 xl:col-span-3">
            <h4 class="text-left md:text-right lg:text-right xl:text-right text-xl text-gray-800 mt-3 md:mt-0 xl:mt-0 lg:mt-0">Social media</h4>
            <div class="mt-6">
              <div class="flex flex-col md:flex-row-reverse lg:flex-row-reverse xl:flex-row-reverse mt-4">
                <li class="flex ml-0 md:ml-12 lg:ml-12 xl:ml-12">
                  <i class="fab fa-facebook-f pt-1 pr-3 text-gray-800"></i>
                  <a href="https://www.facebook.com/USHR-Logistics-104683344309134" class="font-semibold uppercase text-gray-600 transition hover:text-gray-800">Facebook</a>
                </li>
                <li class="flex mt-3 md:mt-0 xl:mt-0 lg:mt-0 ml-0 md:ml-12 lg:ml-12 xl:ml-12">
                  <i class="fab fa-telegram pt-1 pr-3 text-gray-800"></i>
                  <a href="https://t.me/ushrllc1" class="font-semibold uppercase text-gray-600 transition hover:text-gray-800">Telegram</a>
                </li>
                <li class="flex mt-3 md:mt-0 xl:mt-0 lg:mt-0 ml-0 md:ml-12 lg:ml-12 xl:ml-12">
                  <i class="fab fa-instagram pt-1 pr-3 text-gray-800"></i>
                   <a href="https://www.instagram.com/ushrllc/" class="font-semibold uppercase text-gray-600 transition hover:text-gray-800">Instagram</a>
                </li>
              </div>
            </div>
          </div>
          <div class="col-span-6 lg:col-span-6 md:col-span-6 xl:col-span-6 mb-6">
            <div class="mt-10 mx-auto border-t-2">
              <div v-scrollanimation class="text-left py-6">
                <p class="text-sm text-gray-700 font-bold text-center">
                  © 2021 USHR LLC. All rights reserved.
                </p>
              </div>
            </div>
          </div>
          
        </div>
      </div>

    </footer>
  </div>
</template>
<script>
export default {
  
}
</script>
<style scoped>
  .back {
    background: #fff;
  }
  /* transition */
  .before-enter {
    opacity: 0;
    transform: translateY(20px);
    transition: all 1s ease-out;
  }
  .enter {
    opacity: 1;
    transform: translateY(0px);
  }
</style>