<template>
  <div>
    <Edit-navbar />
    <div class="bg-gray-400 flex items-center justify-center h-screen">
      <div
        class="absolute right-0 mr-4 xl:mr-96 lg:mr-96 md:mr-24 top-0 mt-28 xl:mt-20 lg:mt-20 md:mt-36"
      >
        <a class="hover:text-gray-100" @click.prevent="backRoute"
          ><i
            class="fas fa-times text-4xl text-white hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          ></i
        ></a>
      </div>
      <div class="container mx-auto px-2">
        <form @submit.prevent="submitHandler"
          class="login text-center flex flex-col w-full md:w-2/3 xl:w-2/6 lg:w-2/6  h-auto mx-auto border-2 bg-white border-gray-100 shadow-2xl mt-8 px-10 rounded-md"
        >
          <h2 class="text-4xl font-semibold text-gray-800 mt-12">
            NEW <span class="text-blue-500">USER</span>
          </h2>
          <input
            class="border border-gray-800 mt-16 h-12 rounded-md pl-3 text-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            type="text"
            placeholder="Name"
            v-model="name"
          />
          <input
            class="border border-gray-800 mt-8 h-12 rounded-md pl-3 text-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            type="text"
            placeholder="E-mail"
            v-model="email"
          />
          <input
            class="border border-gray-800 mt-8 h-12 rounded-md pl-3 text-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            type="text"
            placeholder="Password"
            v-model="password"
          />
          <button type="submit"
            class="mt-12 mb-16 bg-blue-500 h-12 rounded-md text-white font-semibold text-xl w-64 mx-auto hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 "
          >
            Sig up
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import EditNavbar from "../components/EditNavbar.vue";
export default {
  data: () => ({
      email: '',
      password: '',
      name: ''
    }),
  components: {
    EditNavbar,
  },
  methods: {
    backRoute() {
      this.$router.go(-1);
    },
    async submitHandler() {
        const formData = {
          email: this.email,
          password: this.password,
          name: this.name
        }
        try {
          await this.$store.dispatch('register', formData)
        } catch (e) {
          e
        }
      }
  },
};
</script>
