<template>
  <div>
    <div class="vld-parent">
        <loading :active.sync="isLoading" 
        :can-cancel="false" 
        color="#002482"
        loader="spinner"
        :on-cancel="onCancel"
        background="#F7F9FF"
        opacity="1"
        ></loading>
    </div>
    <Navbar/>
    <Main-Header @scroll="scroll()" v-bind:mainheader="info.first" />
    <div class="section-1 mt-8" ref="pablo">
      <div class="container mx-auto">
        <div class="grid grid-cols-6 gap-4 px-4 xl:px-0 lg:px-0 pt-20 lg:pt-24 xl:24 pb-0 xl:pb-24 lg:pb-24 md:12">
          <div v-scrollanimation class="col-span-6 lg:col-span-2 md:col-span-6 xl:col-span-2 px-0">
            <p class="uppercase text-gray-800 font-normal">ABOUT COMPANY</p>
            <h3 class="text-5xl text-gray-800 font-semibold mt-4">{{ home.company.h1 }}</h3>
            <div class="mt-8 w-28 h-2 bg-blue-500"></div>
          </div>
          <div v-scrollanimation class="col-span-6 lg:col-span-4 md:col-span-6 xl:col-span-4 text-left px-0 xl:px-6 lg:px-6">
            <h4 class="mt-10 font-semibold">{{ home.company.h2 }}</h4>
            <p class="mt-8">{{ home.company.h3 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="section-5">
      <div class="container mx-auto">
        <div class="grid grid-cols-6 gap-4 px-4 xl:px-0 lg:px-0 md:px-4 pt-2 xl:pt-0 lg:pt-0 md:pt-4 pb-0">
          <div v-scrollanimation class="col-span-6 lg:col-span-2 xl:col-span-2 md:col-span-2 px-0">
            <p class="uppercase font-normal">PARTNERS</p>
            <h3 class="text-5xl font-semibold mt-4">OUR CLIENTS & PARTNERS</h3>
            <div class="mt-8 w-28 h-2 bg-blue-500"></div>
          </div>
          <div v-scrollanimation class="col-span-6 lg:col-span-4 xl:col-span-4 md:col-span-4 px-4 sm:px-6 xl:px-6 lg:px-6 md:px-6 mx-auto">
            <div class="grid grid-cols-6 mx-auto items-center mt-8">
              <div class="col-span-6 xl:col-span-3 lg:col-span-3 md:col-span-3">
                <img src="https://firebasestorage.googleapis.com/v0/b/truck-llc.appspot.com/o/Untitled-1.jpg?alt=media&token=8da39e71-787b-47b6-8c42-7ecc5ecd28ef" alt="">
              </div>
              <div class="col-span-6 xl:col-span-3 lg:col-span-3 md:col-span-3 mt-4 md:mt-0 xl:mt-0">
                <img src="https://firebasestorage.googleapis.com/v0/b/truck-llc.appspot.com/o/Untitled-2.jpg?alt=media&token=3c8e04c9-31d6-4b5c-a1f9-bcb07dc79952" alt="">
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div> 
    <div class="section-2 pb-4 pt-10"> 
      <div class="container mx-auto px-4 md:px-4 lg:px-0 xl:px-0">
        <div v-scrollanimation class="flex">
          <h2 class="text-4xl font-semibold mx-auto text-gray-800 border-b-4 pb-3 border-blue-500">ABOUT US</h2>
        </div>
        <div>
          <div class="mt-10">
            <div v-scrollanimation class="border-l-4 border-blue-500">
              <h4 class="text-gray-800 pl-4 text-xl xl:text-2xl lg:text-2xl md:text-2xl">{{ home.about.h1 }}</h4>
            </div>
          </div>
          <div class="mt-10">
            <div v-scrollanimation class="border-l-4 border-blue-500">
              <h4 class="text-gray-800 text-xl xl:text-2xl lg:text-2xl md:text-2xl pl-4">{{ home.about.h2 }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-3 container mx-auto my-12 px-4">
      <div v-scrollanimation class="flex">
        <h2 class="text-4xl font-semibold mx-auto text-gray-800 border-b-4 pb-3 border-blue-500 mb-2">TEAM</h2>
      </div>
      <div class="flex flex-col xl:flex-row xl:justify-between md:flex-row md:justify-between">
        <div v-scrollanimation class="mx-4 mt-4">
          <img class="rounded-lg object-cover w-full h-60 xl:w-full xl:h-auto md:h-80" :src="home.slider.first.image" alt="">
        </div>
        <div v-scrollanimation class="mx-4 mt-4">
          <img class="rounded-lg object-cover w-full h-60 xl:w-full xl:h-auto md:h-80" :src="home.slider.second.image" alt="">
        </div>
        <div v-scrollanimation class="mx-4 mt-4">
          <img class="rounded-lg object-cover w-full h-60 xl:w-full xl:h-auto md:h-80" :src="home.slider.third.image" alt="">
        </div>
      </div>
      <div class="flex flex-col xl:flex-row xl:justify-between md:flex-row md:justify-between mt-0 xl:mt-4 md:mt-4">
        <div v-scrollanimation class="mx-4 mt-4">
          <img class="rounded-lg object-cover w-full h-60 xl:w-full xl:h-auto md:h-80" :src="home.slider.four.image" alt="">
        </div>
        <div v-scrollanimation class="mx-4 mt-4">
          <img class="rounded-lg object-cover w-full h-60 xl:w-full xl:h-auto md:h-80" :src="home.slider.fifth.image" alt="">
        </div>
        <div v-scrollanimation class="mx-4 mt-4">
          <img class="rounded-lg object-cover w-full h-60 xl:w-full xl:h-auto md:h-80" :src="home.slider.six.image" alt="">
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
import MainHeader from '../components/MainHeader'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  data: () => ({
    info: [],
    home: [],
    visible: false,
    animate: true,
    product: [],
    isLoading: true,
    fullPage: false,
  }),
  async mounted () {
    this.home = await this.$store.dispatch('fetchHome')
    this.info = await this.$store.dispatch('fetchMainHeader')
    this.isLoading = false
  },
  methods: {
    scroll() {
      var element = this.$refs['pablo'];
      var top = element.offsetTop;
      window.scrollTo({
        top,
        behavior: 'smooth'
      });
      
    }
  },
  components: {
    MainHeader,
    Navbar,
    Loading,
    Footer,
  }
}
</script>
<style scoped>
  /* transition */
  .before-enter {
    opacity: 0;
    transform: translateY(0px);
    transition: all 1s ease-out;
  }
  .enter {
    opacity: 1;
    transform: translateY(0px);
  }
</style>