<template>
  <nav class="fixed w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-gray-500 bg-opacity-90 -mb-24 z-20">
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
      <div class="w-full relative flex justify-between lg:w-auto  px-4 lg:static lg:block lg:justify-start">
        <router-link to="/" class="text-2xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase text-white opacity-100" href="#pablo">
         <img
            class="w-32 xl:w-36 md:w-32 h-auto"
            src="https://firebasestorage.googleapis.com/v0/b/truck-llc.appspot.com/o/logonew3.png?alt=media&token=6ddb1b93-c319-45bc-ad78-ebeb09e82b57"
            alt=""
          />
        </router-link>
        <button class="text-white cursor-pointer text-2xl leading-none  py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none" type="button" v-on:click="toggleNavbar()">
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div v-bind:class="{'hidden': !showMenu, 'flex': showMenu}" class="lg:flex lg:flex-grow items-center ">
        <ul class="flex flex-col lg:flex-row list-none ml-auto">
          <li class="nav-item ml-2">
            <router-link to="/signup" class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="#pablo">
              New user
            </router-link>
          </li>
          <router-link to="/messages" class="nav-item ml-2">
            <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="#pablo">
              Messages
            </a>
          </router-link>
          <li class="nav-item ml-2">
            <a @click="logout" class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="#">
              LogOut
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "pink-navbar",
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    toggleNavbar: function(){
      this.showMenu = !this.showMenu;
    },
    async logout() {
      await this.$store.dispatch('logout')
      this.$router.go('/')
    }
  }
}
</script>