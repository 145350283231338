<template>
  <div class="h-full">
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        color="#002482"
        loader="spinner"
        background="#F7F9FF"
        opacity="1"
      ></loading>
    </div>
    <Navbar />
    <Main-Header @scroll="scroll()" v-bind:mainheader="info.second" />
    <div class="section-1 pt-24" ref="pablo">
      <div class="container mx-auto">
        <div
          v-scrollanimation
          class="grid grid-cols-6 gap-4 px-0 xl:px-0 lg:px-0 pt-8  lg:pt-12 xl:pt-12 pb-0 xl:pb-12 lg:pb-12 md:pb-12"
        >
          <div
            class="col-span-6 lg:col-span-3 md:col-span-6 xl:col-span-2 px-4"
          >
            <p class="uppercase font-normal">WHY DRIVE FOR USHR</p>
            <h3 class="text-3xl md:text-5xl xl:text-5xl font-semibold mt-4">
              LOOKING FOR EXPERIENCED CDL DRIVERS & LEASE OWNER OPERATORS
            </h3>
            <div class="mt-8 w-28 h-2 bg-blue-500"></div>
          </div>
          <div
            class="col-span-6 lg:col-span-3 md:col-span-6 xl:col-span-4 text-left px-4 xl:px-6 lg:px-0"
          >
            <h4 class="mt-10 text-gray-800 text-2xl font-semibold">
              Requirements:
            </h4>
            <li class="text-xl mt-6 text-gray-800">
              Must have 2 or more years experience
            </li>
            <li class="text-xl text-gray-800">24 Years Old</li>
            <li class="text-xl text-gray-800">CDL-A License</li>
          </div>
        </div>
      </div>
    </div>
    <div class="section-4 back mt-10">
      <div class="container mx-auto px-0 md:px-0 lg:px-0 xl:px-0">
        <div class="flex">
          <h2
            class="text-4xl mt-12 font-semibold mx-auto text-gray-800 border-b-8 pb-3 border-blue-500"
          >
            CONTACT US
          </h2>
        </div>
        <div
          class="grid grid-cols-6 gap-4 pt-12 xl:pt-24 lg:pt-24 md:pt-24 pb-24"
        >
          <div
            class="col-span-6 xl:col-span-2 lg:col-span-2 md:col-span-2 px-4"
          >
            <p class="uppercase font-normal">US HWY RUNNER</p>
            <h3
              class="text-3xl xl:text-5xl lg:text-5xl md:text-5xl  font-semibold mt-3"
            >
              CONTACT DETAILS
            </h3>
            <div
              class="mt-4 xl:mt-8 lg:mt-8 md:mt-8 w-28 h-2 bg-blue-500"
            ></div>
            <div class="mt-8">
              <p class="mt-3">MC# 882367</p>
              <p class="mt-3">DOT# 2537137</p>
              <p class="mt-3">
                Phone:
                <a href="tel:+1 386-569-4185"
                  ><span class="text-blue-500">(386)569-4185</span></a
                >
              </p>
              <p class="mt-3">
                Email:
                <a href="mailto: contact@ushrllc.com" class="cursor"
                  ><span class="text-blue-500">contact@ushrllc.com</span></a
                >
              </p>
              <p class="mt-3">
                Address:
                <a
                  href="https://www.google.com/maps/place/4721+E+Moody+Blvd+%23505,+Bunnell,+FL+32110,+USA/@29.4743913,-81.2270558,17z/data=!3m1!4b1!4m5!3m4!1s0x88e6eb15dd75a305:0x9c165337d7469e90!8m2!3d29.4743913!4d-81.2248671"
                  >4721 E.Moody Blvd, Ste 201, Bunnell, FL 32110</a
                >
              </p>
              <p class="mt-3">Office 386-516-8000 ext. 3</p>
            </div>
          </div>
          <div
            class="col-span-6 xl:col-span-4 lg:col-span-4 md:col-span-4 text-left px-4 xl:px-0 lg:px-0 md:px-4"
          >
            <div class="pr-0 xl:pr-32 lg:pr-32 md:pr-12">
              <form @submit.prevent="sendEmail">
                <p class="text-gray-800">SEND US MESSAGE</p>
                <input
                  v-model="name"
                  class="w-full border shadow-lg border-gray-800 rounded-lg h-10 pl-3 mt-12 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Your Name"
                  type="text"
                />
                
                <input
                  v-model="email"
                  class="w-full border shadow-lg border-gray-800 rounded-lg h-10 pl-3 mt-8 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Your E-mail"
                  type="text"
                />
                <textarea
                  v-model="message"
                  class="w-full border shadow-lg border-gray-800 rounded-lg h-56 pl-3 pt-2 mt-8 overflow-auto focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Your Message"
                  type="text"
                ></textarea>
                <input
                  type="submit"
                  class="mt-6 bg-blue-500 transition text-white px-8 py-2 rounded-lg font-semibold text-xl hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  value="SEND"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import MainHeader from "../components/MainHeader";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { required, minLength } from "vuelidate/lib/validators";
import emailjs from 'emailjs-com';


export default {
  data: () => ({
    info: [],
    visible: false,
    animate: true,
    product: [],
    isLoading: true,
    sent: false,
    fullPage: false,
    name: "",
    email: "",
    message: "",
    ContactForm: {
      name : 'Zasfar',
      email: 'przafar@mail.ru',
      phone: '+998977584224',
      header: 'testtt lorem'
    }
  }),
  async mounted() {
    this.info = await this.$store.dispatch("fetchMainHeader");
    this.isLoading = false;
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
    },
    message: {
      required,
      minLength: minLength(6),
    },
  },
  methods: {
    scroll() {
      var element = this.$refs["pablo"];
      var top = element.offsetTop;
      window.scrollTo({
        top,
        behavior: "smooth",
      });
    },
    async sendEmail() {
      if(this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const formData = {
        name: this.name,
        email: this.email,
        message: this.message,
        date: this.date
      }
      const userId = process.env.VUE_APP_EMAILJS_USER_ID
      try {
        emailjs.send('service_t63llw6', 'template_xqx293f', formData, userId)
        await this.$store.dispatch('sendMessage', formData)

      } catch (error) {
        console.log({error})
      }
      this.name = ''
      this.email = ''
      this.message = ''
    },
  },
  components: {
    Navbar,
    MainHeader,
    Loading,
    Footer,
  },
};
</script>
<style scoped>
.back {
  background-color: #f7f9ff;
}
/* transition */
.before-enter {
  opacity: 0;
  transform: translateY(0px);
  transition: all 3s ease-out;
}
.enter {
  opacity: 1;
  transform: translateY(0px);
}
</style>
