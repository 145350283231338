<template>
  <div class="w-full bg-cover bg-center header" style="height:50rem;">
    <div class="flex flex-wrap items-center justify-center h-full w-full bg-gray-900 bg-opacity-50">
      <div class="text-center">
        <h2 class="text-white text-2xl font-semibold uppercase md:text-3xl px-2 md:px-0 lg:px-0">{{ header.h1 }}</h2>
        <h2 class="text-white text-2xl font-semibold uppercase md:text-3xl mt-4 px-2 md:px-0 lg:px-0">{{ header.h2 }}</h2>
        <router-link to="/send">
          <button class="mt-4 px-8 py-3 bg-blue-600 text-white transition text-xl uppercase font-medium rounded hover:bg-blue-500 focus:outline-none focus:bg-blue-500">JOIN OUR TEAM</button>
        </router-link>
        
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['header']
}
</script>
<style scoped>
  .header {
    background-image: url('../assets/image/2.jpg');
  }
</style>
