<template>
  <div >
    <div class="vld-parent">
        <loading :active.sync="isLoading" 
        :can-cancel="false" 
        color="#002482"
        loader="spinner"
        :on-cancel="onCancel"
        background="#F7F9FF"
        opacity="1"
        ></loading>
    </div>
    <Navbar/>
    <Header v-bind:header="header.first"/>
    <div class="section-1">
      <div class="container mx-auto">
        <div class="grid grid-cols-6 gap-4 px-4 xl:px-0 lg:px-0 pt-12 lg:pt-24 xl:24 pb-0 xl:pb-24 lg:pb-24 md:12">
          <div class="col-span-6 lg:col-span-2 md:col-span-6 xl:col-span-2 px-0">
            <p v-scrollanimation class="uppercase text-gray-800 font-normal">ABOUT COMPANY</p>
            <h3 v-scrollanimation class="text-5xl text-gray-800 font-semibold mt-4">{{ home.company.h1 }}</h3>
            <div v-scrollanimation class="mt-8 w-28 h-2 bg-blue-500"></div>
          </div>
          <div class="col-span-6 lg:col-span-4 md:col-span-6 xl:col-span-4 text-left px-0 xl:px-6 lg:px-6">
            <h4 v-scrollanimation class="mt-10 font-semibold">{{ home.company.h2 }}</h4>
            <p v-scrollanimation class="mt-8">{{ home.company.h3 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="section-2 z-20">
      <div class="container mx-auto">
        <div  class="grid grid-cols-6 gap-4 px-4 md:px-4 lg:px-0 xl:px-0 pt-12 xl:pt-0 lg:pt-4 md:pt-4 pb-12 xl:pb-20 lg:pb-20 md:pb-20 ">
          <div v-scrollanimation v-for="(car, index) in home.image" :key="index" class="col-span-6 lg:col-span-2 md:col-span-6 xl:col-span-2 px-0">
            <div v-bind:style="{ backgroundImage: 'url(' + car.image + ')' }" class="relative bg-cover bg-center w-full text-center h-64 xl:h-80 md:h-80 rounded-md">
              <h1 v-scrollanimation class="absolute inset-x-0 bottom-12 text-white font-bold text-3xl px-4">{{car.name}}</h1>
            </div>
          </div>
          <div class="col-span-6 lg:col-span-2 md:col-span-6 xl:col-span-2 px-0">
            <iframe class="relative bg-cover bg-center w-full text-center h-64 xl:h-80 md:h-80 rounded-md" :src="info.main.first.url">

            </iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="section-4"> 
      <div class="container mx-auto px-4 md:px-4 lg:px-0 xl:px-0">
        <div class="flex">
          <h2 v-scrollanimation class="text-4xl font-semibold mx-auto text-gray-800 border-b-8 pb-3 border-blue-500 ">ABOUT US</h2>
        </div>
        <div>
          <div class="mt-10">
            <div class="border-l-8 border-blue-500">
              <h4 v-scrollanimation class="text-gray-800 pl-4 text-xl xl:text-2xl lg:text-2xl md:text-2xl">{{ home.about.h1 }}</h4>
            </div>
          </div>
          <div class="mt-10">
            <div class="border-l-8 border-blue-500">
              <h4 v-scrollanimation class="text-gray-800 text-xl xl:text-2xl lg:text-2xl md:text-2xl pl-4">{{ home.about.h2 }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-5">
      <div class="container mx-auto">
        <div class="grid grid-cols-6 gap-4 px-4 xl:px-0 lg:px-0 md:px-4 pt-12 xl:pt-24 lg:pt-24 md:pt-24 pb-12">
          <div v-scrollanimation class="col-span-6 lg:col-span-2 xl:col-span-2 md:col-span-3 px-0">
            <p class="uppercase font-normal">PARTNERS</p>
            <h3 class="text-5xl font-semibold mt-4">OUR CLIENTS & PARTNERS</h3>
            <div class="mt-8 w-28 h-2 bg-blue-500"></div>
          </div>
          <div class="col-span-6 lg:col-span-4 xl:col-span-4 md:col-span-3 px-0 sm:px-0 xl:px-0 lg:px-6 md:px-0 mx-auto">
            <div class="grid grid-cols-6 mx-auto items-center mt-8">
              <div class="col-span-6 xl:col-span-3 lg:col-span-3 md:col-span-3">
                <img v-scrollanimation src="https://firebasestorage.googleapis.com/v0/b/truck-llc.appspot.com/o/Untitled-1.jpg?alt=media&token=8da39e71-787b-47b6-8c42-7ecc5ecd28ef" alt="">
              </div>
              <div class="col-span-6 xl:col-span-3 lg:col-span-3 md:col-span-3 mt-4 md:mt-0 xl:mt-0">
                <img v-scrollanimation src="https://firebasestorage.googleapis.com/v0/b/truck-llc.appspot.com/o/Untitled-2.jpg?alt=media&token=3c8e04c9-31d6-4b5c-a1f9-bcb07dc79952" alt="">
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-4 back"> 
      <div class="container mx-auto px-4 md:px-4 lg:px-0 xl:px-0">
        <div class="flex">
          <h2 class="text-4xl mt-12 font-semibold mx-auto text-gray-800 border-b-8 pb-3 border-blue-500">CONTACT US</h2>
        </div>
        <div class="grid grid-cols-6 gap-4 pt-12 xl:pt-24 lg:pt-24 md:pt-24 pb-24">
          <div class="col-span-6 xl:col-span-2 lg:col-span-2 md:col-span-2 px-0">
            <p class="uppercase font-normal">US HWY RUNNER</p>
            <h3 class="text-3xl xl:text-5xl lg:text-5xl md:text-5xl  font-semibold mt-3">CONTACT DETAILS</h3>
            <div class="mt-4 xl:mt-8 lg:mt-8 md:mt-8 w-28 h-2 bg-blue-500"></div>
            <div class="mt-8">
              <p class="mt-3">MC# 882367</p>
              <p class="mt-3">DOT# 2537137</p>
              <p class="mt-3">Phone: <a href="tel:+1 386-569-4185"><span class="text-blue-800">(386)569-4185</span></a></p>
              <p class="mt-3">Email: <a href="mailto: contact@ushrllc.com" class="cursor"><span class="text-blue-500">contact@ushrllc.com</span></a></p>
              <p class="mt-3">Address: <a class="text-blue-500" href="https://www.google.com/maps/place/29%C2%B028'30.9%22N+81%C2%B013'27.1%22W/@29.475253,-81.22419,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x7ba4f464cf47bb7!8m2!3d29.475253!4d-81.22419">4721 E. Moody Blvd, Ste 201, Bunnell, FL 32110</a></p>
              <p class="mt-3">Office 386-516-8000 ext. 3</p>
            </div>
          </div>
          <div class="col-span-6 xl:col-span-4 lg:col-span-4 md:col-span-4 text-left px-0 xl:px-12 lg:px-0 md:px-6">
            <div class="pr-0 xl:pr-32 lg:pr-32 md:pr-12">
              <form @submit.prevent="sendEmail">
                <p class="text-gray-800">SEND US MESSAGE</p>
                <input v-model="name" class="w-full border shadow-lg border-gray-800 rounded-lg h-10 pl-3 mt-12 focus:outline-none focus:ring-2 focus:ring-blue-800 focus:border-transparent" placeholder="Your Name" type="text">
                <input v-model="email" class="w-full border shadow-lg border-gray-800 rounded-lg h-10 pl-3 mt-8 focus:outline-none focus:ring-2 focus:ring-blue-800 focus:border-transparent" placeholder="Your E-mail" type="text">
                <textarea v-model="message" class="w-full border shadow-lg border-gray-800 rounded-lg h-56 pl-3 pt-2 mt-8 overflow-auto focus:outline-none focus:ring-2 focus:ring-blue-800 focus:border-transparent" placeholder="Your Message" type="text"></textarea>
                <input type="submit" class="mt-6 bg-blue-500 transition text-white px-8 py-2 rounded-lg font-semibold text-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-800 focus:ring-opacity-50" value="SEND">
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer'

import Navbar from '../components/Navbar'
import Header from '../components/Header'
import emailjs from 'emailjs-com';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  
  name: 'Home',
  data: () =>({
    info: [],
    categories: [],
    isActive: null,
    name: '',
    email: '',
    message: '',
    visible: false,
    animate: true,
    product: [],
    isLoading: true,
    fullPage: false,
    header: [],
    home: [],
    date: new Date().toISOString().slice(0,10) 

  }),
  async mounted () {
    this.home = await this.$store.dispatch('fetchHome')
    this.info = await this.$store.dispatch('fetchInfo')
    this.header = await this.$store.dispatch('fetchHeader')
    this.categories = this.info.first.categories
    this.isLoading = false
  },
  validations: {
    name: {
      required
    },
    email: {
      required,
    },
    message: {
      required,
      minLength: minLength(6)
    },
  },
  methods: {
    updateInfo(info, image, index) {
      this.info.first.info = info
      this.info.first.image = image
      this.isActive = index
    },
    async sendEmail() {
      if(this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const formData = {
        name: this.name,
        email: this.email,
        message: this.message,
        date: this.date
      }
      const userId = process.env.VUE_APP_EMAILJS_USER_ID
      try {
        emailjs.send('service_t63llw6', 'template_xqx293f', formData, userId)
        await this.$store.dispatch('sendMessage', formData)

      } catch (error) {
        console.log({error})
      }
      this.name = ''
      this.email = ''
      this.message = ''
    },
  },
  components: {
    Navbar,
    Header,
    Loading,
    Footer
  }
}
</script>
<style scoped>
  .active {
    color:#236cf3
  }
  .back {
    background-color: #F7F9FF;
  }
    /* transition */
  .before-enter {
    opacity: 0;
    transform: translateY(0px);
    transition: all 1.1s ease-out;
  }
  .enter {
    opacity: 1;
    transform: translateY(0px);
  }
</style>
