import firebase from 'firebase/app'

export default {
  state: {
    error: null,
  },
  mutations: { 
    setError(state, error) {
      state.error = error
    },
  },
  getters: {
    error: s => s.error,
  },
  actions: {
    async sendMessage({commit}, {name, email, message, date}) {
      try {
        const info = await firebase.database().ref('/info/messages').push({name, email, message, date})
        return {name, email, message, date, id: info.key}
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    async fetchMessage({commit}) {
      try {
        const comment = (await firebase.database().ref('/info/messages').once('value')).val() || {}
        return Object.keys(comment).map(key => ({...comment[key], id: key}))
      } catch (e) {
        commit('setError', e)
        throw e
      } 
    },
    getUid() {
      const user = firebase.auth().currentUser
      return user ? user.uid : null
    },
    async logout({commit}) {
      await firebase.auth().signOut()
      await commit('clearInfo')
    },
  }
}