<template>
  <div class="w-full bg-cover bg-center header h-full -mb-24" v-bind:style="{ backgroundImage: 'url(' + mainheader.image + ')' }">
    <div class="flex-col h-full w-full bg-gray-900 bg-opacity-50 pt-64">
      <div class="text-left md:border-l-8 xl:border-l-8 border-l-8 ml-4 md:ml-12 xl:ml-12 pl-1 md:pl-5 xl:pl-5 -mt-24 -md:mt-24 -xl:mt-24 border-blue-500">
        <h1 v-scrollanimation class="text-white text-3xl font-semibold uppercase md:text-4xl pb-4 px-2 md:px-0 lg:px-0">{{ mainheader.h1 }}</h1>
        <p v-scrollanimation class="text-white text-xl hidden md:block xl:block sm:hidden md:text-xl w-full md:w-1/2 font-semibold uppercase mt-6 px-2 md:px-0 lg:px-0">{{ mainheader.h2 }}</p>
        <a v-scrollanimation href="tel:+386-569-4185" class="text-white text-xl md:text-xl  w-full md:w-1/2 font-semibold uppercase px-2 md:px-0 lg:px-0"><span class="text-blue-500 pr-2">{{ mainheader.h4 }}</span> {{ mainheader.h3 }} </a>
      </div>
      <div class="ml-0 text-center md:text-left xl:text-left md:ml-12 xl:ml-12 pb-24 md:pb-32 xl:32">
        <button v-scrollanimation @click="scroll('pablo')" class="border hover:text-blue-500 transition hover:border-blue-500 text-white text-2xl outline-none focus:outline-none border-white rounded-full w-12 h-12 mt-16"><i class="fas fa-arrow-down"></i></button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['mainheader'],
  methods: {
    scroll() {
      this.$emit('scroll')
    }
  }
}
</script>
<style scoped>
  /* transition */
  .before-enter {
    opacity: 0;
    transform: translateY(0px);
    transition: all 1.1s ease-out;
  }
  .enter {
    opacity: 1;
    transform: translateY(0px);
  }
</style>